import * as React from "react";

import Layout from "../../components/Layout";
import ArticleRoll from "../../components/ArticleRoll";
import { Helmet } from "react-helmet";

export default class InsightsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section mb-6">
          <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Article</title>
            <meta property="og:title" content="Article" />
            <meta name="description" content="Article on Kamsa" />
          </Helmet>
            <div className="block has-text-centered">
              <h1 className="title is-h1">Insights</h1>
              <h4 className="is-h4 is-offset-1">Our latest news and articles</h4>
            </div>

            <ArticleRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
